<template>
    <div>
        <page-title :heading="$t('accounting.lang_showUnpaidInvoice')" show-previous-button :subheading=subheading :icon=icon></page-title>
        <div class="app-main__inner">
                        <DisplayUnpaidInvoice></DisplayUnpaidInvoice>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import DisplayUnpaidInvoice from "../../components/accounting/DisplayUnpaidInvoice";

    export default {
        components: {
            PageTitle,
            DisplayUnpaidInvoice
        },
        props: ["id"],
        data: () => ({
            subheading: '',
            icon: 'pe-7s-search icon-gradient bg-tempting-azure',

        }),
    }
</script>