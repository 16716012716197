<template>
  <div>
    <div class="menuWidth">
      <div class="main-card mb-3 card">
        <div class="cc">
          <div class="grid-menu grid-menu-3col">
            <div class="no-gutters row">
              <div class="col-sm-4 col-xl-4" style="width:50% !important;">
                <button :disabled="this.loading" @click="reprintInvoice"
                        class="btn-icon-vertical btn-square btn-transition btn btn-primary"
                        v-if="!loadingReprint"><i
                    class="lnr-printer btn-icon-wrapper"> </i><b>{{ $t('generic.lang_reprint') + scaleText }}</b>
                </button>

                <div class="text-center btn-icon-wrapper" style="margin-top: 25px;" v-else>
                  <v-progress-circular color="primary" indeterminate width="3"></v-progress-circular>
                </div>
              </div>
              <div class="col-sm-4 col-xl-4" style="width:50% !important;">
                <button :disabled="this.loading" @click="openDialog"
                        class="btn-icon-vertical btn-square btn-transition btn btn-secondary"><i
                    class="lnr-envelope btn-icon-wrapper"> </i>
                  <b>{{ $t('generic.lang_byEmail') }}</b>
                </button>
              </div>
              <div class="col-sm-4 col-xl-4" style="width:50% !important;">
                <button :disabled="this.loading" @click="downloadInvoicePDF"
                        class="btn-icon-vertical btn-square btn-transition btn btn-success"
                        v-if="!loadingDownload">
                  <i class="lnr-download btn-icon-wrapper"></i>
                  <b>{{ $t('generic.lang_download') }}</b>
                </button>

                <div class="text-center btn-icon-wrapper" style="margin-top: 25px;" v-else>
                  <v-progress-circular color="primary" indeterminate width="3"></v-progress-circular>
                </div>
              </div>
            </div>

            <div class="no-gutters row">
              <div class="col-sm-12 col-md-4" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
                <v-dialog
                    :return-value.sync="date"
                    persistent
                    ref="dialog"
                    v-model="modal"
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('accounting.lang_paid_invoice')+':'"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-model="date"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker scrollable v-model="date">
                    <v-spacer></v-spacer>
                    <v-btn @click="modal = false" color="primary" text>{{ $t('generic.lang_cancel') }}</v-btn>
                    <v-btn @click="$refs.dialog.save(date)" color="primary" text>{{ $t('generic.lang_ok') }}</v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>

              <div class="col-sm-12 col-md-4">
                <button @click="setInvoicePaid" class="btn-icon-vertical btn-square btn-transition btn btn-warning"
                        v-if="!loadingInvoicePaid">
                  <i class="btn-icon-wrapper py-0" style="margin-top: -10px">
                    <font-awesome-icon :icon="['fal', 'check-double']" class="black--text actions mt-0 py0" size="2x"
                                       style="font-size: 30px"/>
                  </i>
                  <b>{{ $t('generic.lang_billPaid') }}</b>
                </button>

                <div class="text-center btn-icon-wrapper" style="margin-top: 25px;" v-else>
                  <v-progress-circular color="primary" indeterminate width="3"></v-progress-circular>
                </div>
              </div>

              <div
                  v-if="this.invoiceData.invoiceType != 3 && $store.getters['permissions/checkHybridEnabled']('Hybrid')"
                  class="col-sm-4 col-xl-4"
                  style="width:50% !important;">
                <button @click="voidInvoice" :disabled="this.loading"
                        class="btn-icon-vertical btn-square btn-transition btn btn-danger"><i
                    class="lnr-warning btn-icon-wrapper"> </i>
                  <b>{{ $t('erp.lang_delete_order') }}</b>
                </button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="invoice-box" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
      <table cellpadding="0" cellspacing="0">
        <tr class="top">
          <td colspan="4">
            <table>
              <tr>
                <td class="title">
                  <img :src="invoiceLogoUrl"
                       style="width:100%; max-width:300px;">
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr class="information">
          <td colspan="4">
            <table>
              <tr>


                <td style="text-align:left !important;text-decoration: underline;">
                  {{ invoiceSenderAddress }}
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="information">
          <td colspan="4">
            <table>
              <tr>
                <td>
                  <div v-if="invoiceCustomerData.id > 0">
                    {{ $t('accounting.lang_InvoiceRecipient') }}:<br>
                    <span v-if="invoiceCustomerData.company !== null">{{ invoiceCustomerData.company }}<br></span>
                    {{ invoiceCustomerData.first_name }}
                    {{ invoiceCustomerData.last_name }}<br>{{
                      invoiceCustomerData.street_no
                    }}<br>{{ invoiceCustomerData.zip }}
                    {{ invoiceCustomerData.city }}
                  </div>
                </td>
                <td>
                  {{ $t('accounting.lang_invoiceID') }}: {{ invoiceNoReal }}<br>
                  {{ $t('accounting.lang_dateOfInvoice') }}: {{ saleTimeStamp }}<br>
                  {{ $t('accounting.lang_dateOfDelivery') }}: {{ deliveryTimeStamp }}<br>
                  <span v-if="invoicePaidTimeStamp.length < 5">{{ $t('accounting.lang_paymentDue') }}: {{ invoiceDueTimeStamp }}</span>
                  <span v-if="invoicePaidTimeStamp.length > 5">{{ $t('accounting.lang_paymentMade') }}: {{ invoicePaidTimeStamp }}</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>

      </table>

      <b-table :fields="fields" :items="items"
               hover
               striped :table-variant="this.$vuetify.theme.dark? 'dark' : ''">
        <template #cell(unitPrice)="data">
          {{data.item.unitPrice|currency}}
        </template>
        <template #cell(total)="data">
          {{data.item.total|currency}}
        </template>
        <template #cell(descr)="data">
          {{ data.item.descr + '\n' }}
          <template v-if="data.item.hasOwnProperty('bookedGarnish')">
            <span v-for="(garnish,index) in data.item.bookedGarnish" :key="index" class="pa-0 ma-0 d-block"
                  style="font-size: 12px;line-height: 1.2;">
              &#9656;{{ garnish.qty + 'x' + garnish.name }}&emsp;&emsp;{{ garnish.priceItem | currency }} / {{ garnish.priceStack | currency }}
            </span>
          </template>
        </template>
      </b-table>
      <br>
      <span style="float:right;"><h4>{{ $t('generic.lang_subtotal') }}: {{ invoiceTotalNet | currency }}</h4></span><br
        style="clear:both;">
      <span style="float:right;"><h4>{{ $t('erp.lang_mwst') }}: {{ invoiceTotalTax | currency }}</h4></span><br
        style="clear:both;">
      <span style="float:right;"><h4
          style="font-weight:bold;">{{ $t('generic.lang_total') }}: {{ invoiceTotal | currency }}</h4></span>
      <h4>{{ $t('accounting.lang_InvoiceNotes') }}</h4>

      <br style="clear:both;">

    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>

    <!-- INVOICE PDF DIALOG -->
    <v-dialog persistent v-model="displayInvoiceDialog" width="650">
      <v-card>
        <v-card-text style="height: 600px;">
          <iframe :src="iframePDFContent" height="550" v-if="iframePDFContent.length > 0" width="600"/>
        </v-card-text>

        <v-card-actions style="background-color: white;">
          <v-spacer></v-spacer>
          <v-btn @click="displayInvoiceDialog = false" color="blue darken-1" dark text>{{ $t('generic.lang_close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--email dialog -->
    <v-card-text>
      <v-dialog max-width="600px" persistent v-model="dialog">
        <v-form lazy-validation ref="emailForm">
          <div class="card">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
              {{ $t('accounting.lang_sendingInvoice').replace("{invoiceNo}", this.invoiceNo) }}
            </div>

            <div class="card-body" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
              <v-row>
                <v-col class="pb-0 mb-0" cols="12">

                  <v-combobox
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" :filter="filter"
                      :hide-no-data="!searchEmail"
                      :items="[]"
                      :rules="[v => !!v]"
                      :search-input.sync="searchEmail"
                      @focus="showTouchKeyboard"
                      autofocus
                      clearable
                      dense
                      hide-selected
                      :label="$t('accounting.lang_InvoiceRecipient')"
                      multiple
                      outlined
                      small-chips
                      v-model="form.emails"
                  >
                    <template v-slot:no-data>
                      <v-list-item class="transparent">
                        <v-chip
                            :color="`primary lighten-3`"
                            label
                            small
                        >
                          {{ searchEmail }}
                        </v-chip>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                          :color="`${item.color} lighten-3`"
                          :input-value="selected"
                          label
                          small
                          v-bind="attrs"
                          v-if="item === Object(item)"
                      >
          <span class="pr-2">
            {{ item.text }}
          </span>
                        <v-icon
                            @click="parent.selectItem(item)"
                            small
                        >close
                        </v-icon>
                      </v-chip>
                    </template>

                  </v-combobox>
                </v-col>

                <v-col class="pt-0 mt-0 pb-0 mb-0" cols="12">
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v]" @focus="showTouchKeyboard"
                                clearable dense :label="$t('settings.lang_mail_subject')" outlined
                                v-model="form.subject"/>
                </v-col>

                <v-col class="pt-0 mt-0" cols="12">
                  <quill-editor :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                :rules="[v => !!v]" @focus="showTouchKeyboard" v-model="form.message"></quill-editor>
                </v-col>
                <v-col cols="12">
                  <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                    <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                        :defaultKeySet="touchKeyboard.keySet"
                                        :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                        :options="touchKeyboard.options" class="internalWidth"
                                        id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
                  </div>
                </v-col>

              </v-row>
            </div>

            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right pa-1 pr-3">
              <v-spacer/>
              <v-btn @click="dialog = false" class="ma-1" color="error">
                {{ $t('generic.lang_cancel') }}
              </v-btn>
              <v-btn :disabled="sendLoader" :loading="sendLoader" @click="send" class="ma-1" color="primary">
                {{ $t('support.lang_supportSend') }}
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-dialog>
    </v-card-text>
  </div>

</template>

<style scoped>
.menuWidth {
  max-width: 1200px;
  margin: auto;
  margin-bottom: -22px;
}

.invoice-box {
  background-color: #ffffff;
  max-width: 1200px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
  sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

</style>

<script>
import FileSaver from 'file-saver';
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import mixin from "../../mixins/KeyboardMixIns";
import {mapState} from "vuex";
import {quillEditor} from 'vue-quill-editor';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {createInvoicePrintingData} from "@/plugins/printing/printInvoice";
import {createRetourePrintingData} from "@/plugins/printing/printRetoure";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCheckDouble} from '@fortawesome/pro-light-svg-icons';
import {printDataFromPrinter} from "../../plugins/printing/printerController";
import {geol} from "@/main";

library.add(faCheckDouble)
export default {
  components: {quillEditor, 'font-awesome-icon': FontAwesomeIcon,},

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      date: new Date().toISOString().substr(0, 10),
      modal: false,
      id: null,
      invoiceUID: "",
      items: [],
      search: "",
      loading: false,
      loadingDownload: false,
      loadingReprint: false,
      loadingInvoicePaid: false,
      invoiceData: {},
      invoicePositions: [],
      invoiceCustomerData: [],
      invoiceNo: null,
      invoiceUUID: null,
      invoiceNoReal: null,
      saleTimeStamp: null,
      invoiceType: null,
      invoiceTotal: 0.00,
      invoiceTotalNet: 0.00,
      invoiceTotalTax: 0.00,
      shippingType: null,
      cashierID: null,
      invoicePaidTimeStamp: "",
      invoiceDueTimeStamp: "",
      deliveryTimeStamp: "",
      invoicePositionObj: [],
      displayInvoiceDialog: false,
      iframePDFContent: "",
      invoiceLogoUrl: "",
      invoiceSenderAddress: "",

      //email
      form: {
        emails: [],
        subject: "",
        message: "",
      },
      sendLoader: false,
      dialog: false,
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },

      //
      activator: null,
      attach: null,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      editing: null,
      index: -1,
      menu: false,
      model: [],
      x: 0,
      searchEmail: null,
      y: 0,
    }
  },
  watch: {
    "form.emails": function (val, prev) {
      if (val.length === prev.length) return

      this.form.emails = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[Math.floor((Math.random() * 10) + this.form.emails.length)],
          }
        }

        return v
      })
    },

  },
  computed: {
    ...mapState([
      'api',
      'pos',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    scaleText(){
      return (this.$store.getters['scale/isEnabled']?(this.$i18n.locale==='de'?' -kopie- ':' -copy- '):'')
    },
    fields() {
      return [
        {
          key: "pos",
          sortable: false,
          label: 'Pos.'
        },
        {
          key: "descr",
          sortable: false,
          label: this.$i18n.t('generic.lang_description')
        },
        {
          key: "bookedGarnish",
          sortable: false,
          thClass: 'd-none',
          tdClass: 'd-none',
          trClass: 'd-none',
        },
        {
          key: "qty",
          sortable: false,
          label: this.$t('erp.lang_QTY')
        },
        {
          key: "unitPrice",
          sortable: false,
          label: this.$t('erp.lang_ware_UnitPrice'),
          headerTitle: this.$t('generic.lang_ware_UnitPrice')
        },
        {
          key: "total",
          sortable: false,
          label: this.$t('generic.lang_total')
        }
      ]
    },
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    }
  },

  mounted() {
    this.getData();

    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
        geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  },

  methods: {
    send() {
      if (!this.$refs.emailForm.validate()) return;
      this.sendLoader = true;

      let data = {};
      Object.assign(data, this.form);
      data.emails = this.form.emails.map((x) => x.text);
      data.invoiceID = this.$route.params.id;
      this.axios.post(ENDPOINTS.EMAILS.INVOICES, data).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });

          this.dialog = false;
          this.$refs.emailForm.reset();
          this.form.message = "";
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.sendLoader = false;
      })
    },
    voidInvoice() {
      let navRoute = "";
      //NAV TO RETAIL IF RETAIL MODULE IS ACTIVE
      if (this.$store.getters['permissions/checkModule'](1)) {
        navRoute = '/pos/retail';

      }
      //NAV TO GASTRO IF GASTRO MODULE IS ACTIVE
      else if (this.$store.getters['permissions/checkModule'](2)) {
        // CHECK IF WE HAVE SAVED TABLE OR NOT
        // SET TRESENVERKAUF
        navRoute = '/pos/gastro';
      }

      this.$router.push({
        path: navRoute,
        query: {
          voidInvoice: this.invoiceNo
        }
      });
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
    },
    getColor(currentQTY, minQTY, standQTY) {
      if (currentQTY <= minQTY)
        return "error";
      else if (currentQTY >= minQTY && currentQTY < standQTY)
        return "warning";
      else return "success";

    },
    openDialog() {
      this.form.subject = "Rechnung " + this.invoiceNo + ' | ' + this.invoiceSenderAddress;
      this.dialog = true;

      if (this.invoiceCustomerData.email && this.invoiceCustomerData.email.length > 0) {
        this.$nextTick(() => {
          this.searchEmail = this.invoiceCustomerData.email
          this.form.emails = [];
          this.form.emails.push(this.invoiceCustomerData.email)
        })
        this.$forceUpdate()
      }
    },
    goBack() {
      this.$router.push('/accounting/SearchInvoices');
    },
    setInvoicePaid() {
      this.loadingInvoicePaid = true;


      this.axios.post(ENDPOINTS.ACCOUNTING.INVOICE.PAID, {

        paidDate: this.date,
        invoiceUUID: this.invoiceUUID


      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: "",
            color: "success"
          });
          this.$router.push({name: 'accounting.unpaidInvoices'})
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });


    },
    downloadInvoicePDF() {
      this.loadingDownload = true;
      this.axios.post(ENDPOINTS.ACCOUNTING.DISPLAYINVOICE.PRINTA4, {
            invoiceID: this.invoiceUID
          },
          {
            responseType: 'arraybuffer'
          }).then((res) => {

        //GENERATE PDF CONTENT FOR iFRAME
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        this.iframePDFContent = URL.createObjectURL(blobContent);

        this.displayInvoiceDialog = true;

        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Rechnung" + this.invoiceNoReal + ".pdf");

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingDownload = false;
      })
    },
    reprintInvoice() {
      this.loadingReprint = true;
      var printData;

      //RECHNUNGS DRUCK
      if (this.invoiceData.invoiceType === 1 || this.invoiceData.invoiceType === 2) {
        //RE-MAP INVOICE POSITIONS
        let invoicePositions = this.items.map((invoiceItem) => {
          return {
            amount: invoiceItem.qty,
            ean: invoiceItem.ean,
            name: invoiceItem.descr,
            taxValue: invoiceItem.tax,
            sellPrice: invoiceItem.unitPrice,
            originalSellPrice: invoiceItem.originalSellPrice,
            isVoid: invoiceItem.isVoided,
            discount: invoiceItem.discount,
            selectedExtras: []
          };
        });

        //GENERATE INVOICE DATA
        printData = createInvoicePrintingData(invoicePositions, {
          saleID: this.invoiceNo,
          cashierID: this.invoiceData.cashierID,
          userID: this.invoiceData.sellerID,
          ts: this.invoiceData.invoiceTimestamp,
          tableNo: this.invoiceData.tableName,
          moneyGiven: this.invoiceData.moneyGiven,
          paymentType: this.invoiceData.typeOfPayment
        }, {
          paperType: 80
        });
      }

      //RETOURE DRUCK
      if (this.invoiceData.invoiceType === 3) {
        //CALCULATE INVOICE TOTAL SUM + RE-MAP INVOICE POSITIONS
        let invoiceTotal = 0;
        let invoicePositions = [];

        this.items.forEach((item) => {
          //TOTAL SUM
          invoiceTotal += item.unitPrice * item.qty;

          invoicePositions.push({
            amount: invoiceItem.qty,
            ean: invoiceItem.ean,
            name: invoiceItem.descr,
            taxValue: invoiceItem.tax,
            sellPrice: invoiceItem.unitPrice,
            originalSellPrice: invoiceItem.originalSellPrice,
            isVoid: invoiceItem.isVoided,
            discount: invoiceItem.discount,
            selectedExtras: []

          });
        }, 0);


        //GET PRINTING XML DATA
        printData = createRetourePrintingData(invoicePositions, {
          total: invoiceTotal,
          saleID: this.invoiceNo,
          cashierID: this.invoiceData.cashierID,
          userID: this.invoiceData.sellerID,
          ts: this.invoiceData.invoiceTimestamp,
        }, this.invoicePrinter.paperType);

      }

      if (this.invoiceData.fiscalJSONObj !== null) {
        //FIND LAST CUT OF PRINT DATA(INDEX)
        let lastCutIndex = null;

        printData.forEach((element, index) => {
          if (element.hasOwnProperty("cut"))
            if (element.cut)
              lastCutIndex = index;
        });

        try {
        //DELETE LAST CUT, BECAUSE WE WILL ADD CUT AFTER TSE DATA
        if (lastCutIndex !== null)
          this.$delete(printData, lastCutIndex);


          printData.push({
            feed: 1
          });

          printData.push({
            fontSize: 1
          });

          printData.push({
            font: "B"
          });

          let fiscalJSON = this.invoiceData.fiscalJSONObj;

          if (fiscalJSON.hasOwnProperty("firstOrder")) {
            printData.push({
              text: "1. " + geol.t('generic.lang_best') + ".: " + fiscalJSON.firstOrder.orderCreated
            });
          }

          printData.push({
            text: geol.t('accounting.lang_eazSerNr') + ": " + this.api.auth.posID + " - " + this.api.auth.cashierID
          });
          printData.push({
            text: geol.t('accounting.lang_tseSerNr') + ": " + fiscalJSON.tse?.serialNumber
          });
          printData.push({
            text: geol.t('accounting.lang_tseStart') + ": " + fiscalJSON.transaction?.startTime
          });
          printData.push({
            text: geol.t('accounting.lang_tseStop') + ":  " + fiscalJSON.transaction?.stopTime
          });
          printData.push({
            text: geol.t('accounting.lang_tseTrxNr') + ": " + fiscalJSON.transaction?.transactionNumber
          });
          printData.push({
            text: geol.t('accounting.lang_tseSigCount') + ":" + fiscalJSON.transaction?.signatureCounter
          });
          printData.push({
            text: geol.t('accounting.lang_signatur') + ":  " + fiscalJSON.transaction?.signature
          });

          printData.push({
            align: "center"
          });

          //ADD QR CODE
          printData.push({
            qrCode: fiscalJSON.qrCode
          });

          printData.push({
            cut: true
          });

        } catch (err) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_tseError') + ':' + err,
            color: "error"
          });
          return;
        }
      } else {
        //CHECK IF LAST ELEMENT IS CUT
        let lastElememt = printData[(printData.length - 1)];

        if (!lastElememt.hasOwnProperty("cut"))
          printData.push({cut: true});
      }

      printDataFromPrinter(this.invoicePrinter, printData).then(() => {

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingReprint = false;
      })

    },
    getData() {
      this.loading = true;
      this.invoiceUID = this.$parent.id;

      this.axios.post(ENDPOINTS.ACCOUNTING.DISPLAYINVOICE.GET, {
        invoiceID: this.invoiceUID
      }).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.invoiceUUID = res.data.data.invoiceData.invoiceUUID;
          this.invoiceNo = "" + res.data.data.invoiceData.invoiceSaleRealm + res.data.data.invoiceData.invoiceSaleID + res.data.data.invoiceData.invoiceRandomizerID;
          this.invoiceNoReal = res.data.data.invoiceData.invoiceSaleID;
          this.saleTimeStamp = res.data.data.invoiceData.saleTimeStamp;
          this.invoicePaidTimeStamp = res.data.data.invoiceData.invoicePaidTimeStamp;
          this.invoiceDueTimeStamp = res.data.data.invoiceData.invoiceDueTimeStamp;
          this.shippingType = res.data.data.invoiceData.shippingType;
          this.invoiceTotalTax = res.data.data.invoiceData.totalTax;
          this.deliveryTimeStamp = res.data.data.invoiceData.deliveryTimeStamp;
          this.invoiceTotal = res.data.data.invoiceData.invoiceTotal;
          this.invoiceTotalNet = res.data.data.invoiceData.invoiceTotalNet;
          this.items = res.data.data.invoicePositionData;
          this.invoiceCustomerData = res.data.data.invoiceData.customer_data;
          this.invoiceLogoUrl = res.data.data.invoiceData.invoiceLogoURL;
          this.invoiceSenderAddress = res.data.data.invoiceData.invoiceSenderInfo;
          this.invoiceData = res.data.data.invoiceData;

          this.invoiceData = res.data.data.invoiceData;
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    resetData() {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList() {
      this.resetData();
      this.tab = 0;
    },
  }
}
</script>
